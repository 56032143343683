import React from 'react';
import styled from 'styled-components';
import { ListServicesData } from '../data';
import Service from './Service';

const Container =styled.div`
   padding-bottom: 100px;
  background-size: cover;
  background: #fff;
`

const ListServices = () => {
  return <Container>
      {ListServicesData.map((item)=>(
          <Service key={item.id} title={item.title} list={item.list} img={item.img} ltr={item.ltr}/>
      ))}
  </Container>;
};

export default ListServices;
