import React from 'react'
import styled from 'styled-components'
import fb from '../assets/facebook.png'
import ln from '../assets/linkedin.png'
import bsi from '../assets/bsigray.png'
import link from '../assets/link.png'
import { mobile } from '../../Responsive'

const Container=styled.div`
    background: ${props=>props.background};
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:10px 20px;
    border-radius:${props=>props.borderRaduis && '45px 45px 0 0'} ;
`
const SocialMedia=styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`
const Content=styled.div`
    padding-top: 20px;
`
const Link=styled.a`
    width: 100%;
    text-align: center;
`
const Bottom=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
`
const Infos=styled.div`
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 40px;
    color: #333;
    ${mobile({fontSize:11})}
`
const Border=styled.div`
    background: #333;
    height: 1px;
    width: 100%;
`
const Div=styled.div`
    font-size: 11px;
    font-weight: normal;
    text-align: center;
    margin-top: 10px;
    ${mobile({fontSize:10})}
`

const Footer = (props) => {
  return (
    <Container background={props.background} borderRaduis={props.borderRaduis}>
        <Content>
            <Link href='https://bsiteam.ma/'>
                <img src={bsi} style={{width:100,marginBottom:30}} alt='bsiteam logo'/>
            </Link>
        </Content>
        <SocialMedia>
            <a href='https://web.facebook.com/BSI-Team-102619871190450' target='_blank' rel="noopener noreferrer">
                <img src={fb} alt='facebook'/>
            </a>
            <a href='https://www.linkedin.com/company/bsiteam' target='_blank' rel="noopener noreferrer">
                <img src={ln} alt='linkedin'/>
            </a>
            <a href='https://bsiteam.ma/' target='_blank' rel="noopener noreferrer">
                <img src={link} alt='link' width={16} />
            </a>
        </SocialMedia>
        <Bottom>
            <Infos>
                Les orchidées 3, Imm D Boulevard Mohammed VI, 28800<br/>
                Tél: +212 660-966925 / +212 660-505245 / +212 661 724 204
            </Infos>
            <Border/>
          <Div>
              Vous recevez cet email car vous êtes abonné à la newsletter BSITEAM.<br/>
            Pour mettre à jour vos centres d'intérêt BSITEAM,
             <a style={{color:'gary',textDecoration:'underline'}} href='https://bsiteam.ma/'>suivez ce lien</a>
         </Div>
         <span style={{width:'100%',textAlign:'center',fontSize:10,marginTop:20}}>© 2022 bsiteam </span>
        </Bottom>
    </Container>
  )
}

export default Footer