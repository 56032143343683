import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import Promotion from "./promotion/Promotion";
import Promotion2 from "./promotion/Promotion2";
import Promotion3 from "./promotion/Promotion3";
import Promotion4 from "./promotion/Promotion4";
import Promotion5 from "./promotion/Promotion5";
import Promotion6 from "./promotion/Promotion6";
import Promotion7 from "./promotion/Promotion7";
import Promotion8 from "./promotion/Promotion8";
import Promotion9 from "./promotion/Promotion9";
import Promotion10 from "./promotion/Promotion10";



function App() {
  return (
    <Router >
      <Navbar/>
      <Switch>
        <Route exact path='/' component={Home}>
          <Home/>
        </Route>
        <Route  path='/about' component={About}>
          <About/>
        </Route>
        <Route  path='/services' component={Services}>
          <Services/>
        </Route>
        <Route  path='/contact' component={Contact}>
          <Contact/>
        </Route>
        <Route  path='/hp-240222' component={Promotion}>
          <Promotion/>
        </Route>
        <Route  path='/intenso-250222' component={Promotion2}>
          <Promotion2/>
        </Route>
        <Route  path='/dell-280222' component={Promotion3}>
          <Promotion3/>
        </Route>
        <Route  path='/lenovo-010322' component={Promotion4}>
          <Promotion4/>
        </Route>
        <Route  path='/lenovo-020322' component={Promotion5}>
          <Promotion5/>
        </Route>
        <Route  path='/lenovo-040322' component={Promotion6}>
          <Promotion6/>
        </Route>
        <Route  path='/asus-060322' component={Promotion7}>
          <Promotion7/>
        </Route>
        <Route  path='/hp-080322' component={Promotion8}>
          <Promotion8/>
        </Route>
        <Route  path='/dell-240322' component={Promotion9}>
          <Promotion9/>
        </Route>
        <Route  path='/dell-220322' component={Promotion10}>
          <Promotion10/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
