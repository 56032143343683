import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {motion} from 'framer-motion'
import {mobile} from '../Responsive'
import compass from '../assets/compass.svg'
import background from '../assets/background.jpg'

const ContainerAll=styled(motion.div)`
  background: linear-gradient(to right,rgba(0,0,0,.3),rgba(0, 0, 0,.5),rgba(0, 0, 0,.8)),url(${background});
  background-size:cover;
  ${mobile({height:'100vh'})};
`
const Container=styled.div`
  height: 100vh;
  padding: 90px 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${mobile({padding:'10px 10px'})};
`
const TitleContainer=styled.div`
  padding: 20px 0;
  display: flex;
  align-items: center;
`
const Title=styled(motion.h1)`
  color: #fff;
  font-size:30px;
  font-weight: 500;
  padding-left: 10px;
  ${mobile({fontSize:'20px',paddingLeft:5})};
`
const BTitle=styled(motion.h1)`
  color: #fff;
  font-size:55px;
  font-weight: 500;
  text-align: center;
  margin: 10px 0 20px 0;
  ${mobile({fontSize:'30px',textAlign:'center',})};
`
const Button = styled(motion.button)`
  color: #fff;
  border: 3px solid #fff;
  background: transparent;
  letter-spacing: 2px;
  margin-top: 30px;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 60px;
  transition: 300ms ease;
  &:hover{
    color: #fff;
    background: #307BB2;
    border: 3px solid #307BB2;
  }
  ${mobile({fontSize:'18px',padding:'10px 40px'})};
`
const Border=styled(motion.div)`
  width:80px;
  height:4px;
  background: #DB2625;
  border-radius:10px;
  margin-right: 10px;
  ${mobile({height:2.5,})}
`
const CompassIcon =styled.img`
  ${mobile({width:18})}
`


const Home = () => {
  return <ContainerAll>
      <Container className='home'>
        <TitleContainer>
          <Border
            initial={{ opacity: 0, scale: 0}}
            animate={{ opacity: 1, scale: 1}}
            transition={{duration:.5}}
          />
            <motion.div 
              initial={{ opacity: 0,x:-100, scale: 0}}
              animate={{ opacity: 1,x:0, scale: 1}}
              transition={{duration:.3}}
            >
            <CompassIcon src={compass} alt='compass' width={30} height={30}/>
            </motion.div>
          <Title
            className='minititle'
            initial={{ opacity: 0, x:100, scale: .5}}
            animate={{ opacity: 1, x:0, scale: 1 }}
            transition={{duration:.3}}
          >
          BSITEAM
          </Title>
        </TitleContainer>
          <BTitle
            className='title'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{duration:1}}
          >Intégrateur de solution IT et Energétique</BTitle>
          <Link to='/about'>
            <Button
              whileHover={{scale:1.05}}
              whileTap={{scale:0.95}}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition:{duration:1} }}
            >Commencer</Button>
          </Link>
      </Container>
  </ContainerAll>;
};

export default Home;
