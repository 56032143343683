import React from 'react';
import styled from 'styled-components';
import { ListItemImages } from '../data';
import { mobile } from '../Responsive';

const Container=styled.div`
    display: flex;
  background: #eee;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mobile({padding:'10px 30px',flexDirection:'column'})}
`
const ListImage = styled.ul`
  display: flex;
  list-style: none;
  margin-bottom: 10px;
  flex-wrap: wrap;
  justify-content: center;
  ${mobile({flexWrap:'wrap',justifyContent:'center'})}
`
const ListItemImage = styled.li`
padding-top: 10px;
`
const Image=styled.img`
margin-right: 10px;
height:60px;
width: 60px;
${mobile({height:30})}
`

const Partenaires = () => {

  return <Container>
       <ListImage>
         {ListItemImages.map((item)=>(
          <ListItemImage><Image key={item.key} src={item.url} alt={item.alt}/></ListItemImage>
         ))}
      </ListImage>
  </Container>;
};

export default Partenaires;
