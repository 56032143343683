import React from 'react';
import styled from 'styled-components';
import { mobile } from '../Responsive';
import ry from'../assets/ry.svg'
import done from'../assets/done.svg'
import listen from '../assets/listen.svg'
import cr from '../assets/cr.svg'
import lamp from '../assets/lamp.svg'
import bgQuality from '../assets/bgQuality.png'

const Container=styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-top:150px;
  margin-bottom: 40px;
  ${mobile({marginTop:350})}
`
const TitleContainerQ=styled.div`  
  margin-bottom: 20px;
`
const TitleQ=styled.h1`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: 40px;
  text-transform: uppercase;
  padding-bottom: 10px;
  text-align: center;
  color: #2D2D37;
  ${mobile({fontSize:26})}
`
const QualityGrid=styled.div`
  width:60vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 80px;
  ${mobile({gap:40,width:'100%'})}
`
const QualityItem=styled.div`
  width: 250px;
  height: 170px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: default;
  transition: 10ms ease-in-out;
  background: url(${bgQuality});
  border-radius: 10px;
  ${mobile({height:150})}
`
const DescQ=styled.h3`
  color: #2D2D37;
  font-family: 'Montserrat' ,sans-serif;
  font-size: 22px;
  font-weight: 800;
  padding: 30px 0 15px 0;
  ${mobile({fontSize:16})}
`
const DescQI=styled.p`
  color: #555;
  padding-bottom: 30px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
`
const Br=styled.div`
  width:250px;
  height:2.5px;
  background:#DB2625;
  margin-bottom:50px;
  text-align:start;
`

const Quality = () => {
  return <Container className='quality'>
       <TitleContainerQ>
            <TitleQ>Nos qualités</TitleQ>
            <Br/>
        </TitleContainerQ>
        <QualityGrid>
            <QualityItem>
              <img src={done} style={{height:35}} alt='done'/>

              <DescQ>QUALITE</DescQ>
              <DescQI>Perfectionnistes dans l’âme</DescQI>
            </QualityItem>
            <QualityItem>
              <img src={ry} style={{height:35}} alt='ry'/>
              <DescQ>REACTIVITE</DescQ>
              <DescQI>Passer très rapidement à l’action</DescQI>
            </QualityItem>
            <QualityItem>
              <img src={listen} style={{height:35}} alt='listen'/>
              <DescQ>ECOUTE</DescQ>
              <DescQI>Mieux vous connaître</DescQI>
            </QualityItem>
            <QualityItem>
              <img src={cr} style={{height:35}} alt='cr'/>
              <DescQ>CREATIVITE</DescQ>
              <DescQI>Etre créatif, c’est inné</DescQI>
            </QualityItem>
            <QualityItem>
            <img src={lamp}  alt='lamp' style={{height:35}}/>
              <DescQ>ASSISTANCE</DescQ>
              <DescQI>Mieux vous servir</DescQI>
            </QualityItem>
        </QualityGrid>
  </Container>;
};

export default Quality;
