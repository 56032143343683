import React from 'react'
import styled from 'styled-components'
import { mobile } from '../Responsive'
import imgPromo from './assets/dellimg.png'
import imgpc from './assets/pc.png'
import Footer from './components/Footer'

const ContainerAll=styled.div`
    position: relative;
    z-index: 11111;
    background: #078BAC;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    ${mobile({overflow:'hidden'})}
`
const Container=styled.div`
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    ${mobile({width:'90%'})}
`
const Item=styled.div`
    width: 100%;
    background: #7CC5D8;
    margin-top: 20px;
    border-radius: 45px;
    display: flex;
    align-items: center;
    padding: 100px 50px;
    ${mobile({flexDirection:'column',padding:'20px 0'})}
`
const Img=styled.div`
    width: 50%;
    ${mobile({order:1,width:'100%',textAlign:'center'})}
`
const InfoContainer=styled.div`
    width: 50%;
    padding: 0 50px 0 0;
    ${mobile({order:2,padding:30,width:'100%'})}
`
const Title=styled.h1`
    margin-bottom: 30px;
    color:#078BAC;
    ${mobile({fontSize:30,textAlign:'center',marginTop:20})}
`
const Desc=styled.p`
    margin-bottom: 40px;
`
const Price=styled.div`
    font-size:25px;
    font-weight:600;
    color:#078BAC;
    padding:20px 0 10px 0;
`
const SubPrice=styled.div`
    font-weight: 500;
`

const Promotion3 = () => {
  return (
    <ContainerAll>
        <Container>
            <img src={imgPromo} style={{width:'100%'}} alt='promotion'/>
            <div style={{background:'#7CC5D8',height:'20px',width:'100%'}}/>
            <Item>
                <InfoContainer>
                    <Title>
                        Dell Vostro i3
                    </Title>
                    <Desc>
                        Ordinateur portable Dell Vostro 3500  15,6 HD (1366 x 768) ) i3-1115G4 4Gx1, DDR4, 2666 MHz,1 To HDD 5400 tr/min 2,5  , Ubuntu Linux 20.04,802.11ac 1x1 WiFi et Bluetooth, 1Y Partner Led Carry In Service (clavier numérique)
                    </Desc>
                    à partir de :
                
                <Price style={{}}>
                    4 230 DH/HT
                </Price>
                <SubPrice >
                    au lieu de : <span style={{textDecoration:'line-through'}}>4 600 DH/HT</span>
                </SubPrice>
                </InfoContainer>
                <Img>
                    <img src={imgpc}  style={{height:'300px',}} alt='Dell Labtop'/>
                </Img>
            </Item>
            <div style={{background:'#7CC5D8',height:'20px',width:'100%',margin:'20px 0'}}/>
           <Footer background='#7CC5D8' borderRaduis={true}/>
        </Container>
    </ContainerAll>
  )
}

export default Promotion3