import React from 'react'
import styled from 'styled-components'
import { mobile } from '../Responsive'
import Section from './components/Section'
import pr from './assets/pr.png'
import pc from './assets/pcdellvestro.png'
import Footer from './components/Footer'

const Container=styled.div`
    position: relative;
    z-index: 11111;
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #282828;
`
const ContainerP=styled.div`
    width: 40%;
    ${mobile({width:'100%'})}
`

const Promotion9 = () => {
  return (
    <Container>
        <ContainerP>
            <img src={pr} alt='promotion' style={{width:'100%'}}/>
            <Section 
                img={pc} 
                title='DELL Vostro i3'
                desc='Dell Vostro 3888 i3-10100 4GB 1TB Ubuntu, WiFi and Bluetooth 12M'
                price='4 049'
                ltr={true}
                bg='#829DB2'
                colorTitle='#000'
                colorPrice='#fff'
                colorDescPrice='#000'
                colorDesc='#000'
            />
            <Section 
                img={pc} 
                title='DELL Vostro i5'
                desc='Dell Vostro 3888 i5-10400 4GB 1TB Ubuntu WiFi and Bluetooth 12M'
                price='5 149'
                ltr={false}
                bg='#829DB2'
                colorTitle='#000'
                colorPrice='#fff'
                colorDescPrice='#000'
                colorDesc='#000'
            />
            <Section 
                img={pc} 
                title='DELL MT Vostro i7'
                desc='DELL MT Vostro 3888 Intel i7-10700 8Go 1To HDDFreedos 12M'
                price='7 099'
                ltr={true}
                bg='#829DB2'
                colorTitle='#000'
                colorPrice='#fff'
                colorDescPrice='#000'
                colorDesc='#000'
            />
            
            <br/>
            <br/>
            <Footer background='#829DB2' borderRaduis={true} />
        </ContainerP>
    </Container>
  )
}

export default Promotion9