import React from 'react'
import styled from 'styled-components'
import { mobile } from '../Responsive'
import Section from './components/Section'
import hppromo from './assets/hp.jpg'
import hp1 from './assets/hp1.png'
import hp2 from './assets/hp2.png'
import hp3 from './assets/hp3.png'
import hp4 from './assets/hp4.png'
import Footer from './components/Footer'

const Container=styled.div`
    position: relative;
    z-index: 11111;
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #B8EAF9;
`
const ContainerP=styled.div`
    width: 40%;
    ${mobile({width:'100%'})}
`

const Promotion8 = () => {
  return (
    <Container>
        <ContainerP>
            <img src={hppromo} alt='asus promotion' style={{width:'100%'}}/>
            <Section 
                img={hp1} 
                title='HP Notebook Celeron'
                desc='HP 15 Celeron N4020 dual 15.6" 4GB 1TB W10H6 Gris'
                price='2 849'
                ltr={true}
                bg='#7EC5D9'
                colorTitle='#487b8b'
                colorPrice='#fff'
                colorDescPrice='#487b8b'
                colorDesc='#487b8b'
            />
            <Section 
                img={hp2} 
                title='HP Notebook i3'
                desc='HP 15 i3-1115G4 15,6" 4GB 256 GB SSD Win 10H SL Gris'
                price='4 249'
                ltr={false}
                bg='#7EC5D9'
                colorTitle='#487b8b'
                colorPrice='#fff'
                colorDescPrice='#487b8b'
                colorDesc='#487b8b'
            />
            <Section 
                img={hp3} 
                title='HP Notebook i5'
                desc='HP 15 i5-1135G7 15,6" 8GB 256 GB SSD Win 10H SL Gris'
                price='5 650'
                ltr={true}
                bg='#7EC5D9'
                colorTitle='#487b8b'
                colorPrice='#fff'
                colorDescPrice='#487b8b'
                colorDesc='#487b8b'
            />
            <Section 
                img={hp4} 
                title='HP Notebook i5'
                desc='HP 15 i5-1135G7 15.6" 8GB 256GB SSD CG Nvidia GeForce MX350 2GB W10H6 SL Gris'
                price='6 349'
                ltr={false}
                bg='#7EC5D9'
                colorTitle='#487b8b'
                colorPrice='#fff'
                colorDescPrice='#487b8b'
                colorDesc='#487b8b'
            />
            <br/>
            <br/>
            <Footer background='#7EC5D9' borderRaduis={true} />
        </ContainerP>
    </Container>
  )
}

export default Promotion8