import React from 'react'
import styled from 'styled-components'
import disk from './assets/disk.png'
import { mobile } from '../Responsive'
import Footer from './components/Footer'

const Container=styled.div`
  position: relative;
  z-index: 11112;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;
`
const ContainerAll=styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  ${mobile({width:'100%'})}
`
const Promotion2 = () => {
  return (
    <Container>
      <ContainerAll>
        <img src={disk} alt="hard desk promotion" style={{width:'100%'}}/>
        <Footer background='#EFF2F7'/>
      </ContainerAll>
    </Container>
  )
}

export default Promotion2