import React from 'react';
import Footer from '../components/Footer';
import TopContainerTitle from '../components/TopContainerTitle';
import ListServices from '../components/ListServices';

const Services = () => {
  return <>
    <TopContainerTitle title='Services'/>
    <ListServices/>
    <Footer/>
  </>;
};

export default Services;
