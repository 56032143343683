import React from 'react'
import { mobile } from '../Responsive'
import img from './assets/LenovoThinkbook.png'
import styled from 'styled-components'
import Footer from './components/Footer'


const Container=styled.div`
    position: relative;
    z-index: 11111;
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const ContainerP=styled.div`
    width: 60%;
    ${mobile({width:'100%'})}
`

const Promotion6 = () => {
  return (
    <Container>
        <ContainerP>
            <img src={img} style={{width:'100%'}} alt='lenovo promotion'/>
            <Footer background='#eee'/>
        </ContainerP>
    </Container>
  )
}

export default Promotion6