import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import { mobile } from '../Responsive';
import TopContainerTitle from '../components/TopContainerTitle';
import emailjs from 'emailjs-com'

const Container=styled.div`
  padding: 0 60px;
  width: 100%;
  display: flex;
  color: #fff;
  margin: 50px 0 100px 0;
  background: #fff;
  ${mobile({ flexDirection:'column',marginBottom:300,padding:20})}
`
const Form=styled(motion.form)`
  flex: 1;
  display: flex;
  padding: 50px;
  flex-direction: column;
  ${mobile({ padding:0,marginBottom:10})}
`
const LocationContainer=styled(motion.div)`
  flex: 1;
  margin: 100px;
  position: relative;
  ${mobile({ margin:'10px 0',display:'flex',justifyContent:'center'})}
`
const Square=styled.div`
  position: absolute;
  background: #2D7CB4;
  width: 300px;
  height: 400px;
  right: -40px;
  top: -40px;
  border-radius:.3rem;
  ${mobile({ width:150,height:200,top:'-20px',right:'-20px',display:'none'})}
`
const Content=styled(motion.div)`
  position: absolute;
  color: #2D2D37;
  width: 100%;
  border-radius:.4rem;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  ${mobile({height:'50vh',width:'auto ',position:'static'})}
`

const Input=styled.input`
  padding:10px 10px 10px 5px;
  height: 40px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 17px;
  color: #333;
  background: rgba(255,255,255,.8);
  border: none;
  border-bottom: 2px solid #999;
  &:hover{
    border-bottom: 2px solid #2D7CB4;
  }
`
const DescInput=styled.textarea`
margin-top: 20px;
  padding: 10px;
  font-size: 17px;
  color: #333;
  background: rgba(255,255,255,.8);
  border: 2px solid #999;
  border-radius: .25rem;
  &:hover{
    border: 2px solid #2D7CB4;
  }
`
const Button=styled(motion.button)`
  height: 50px;
  width: 100%;
  color: #fff;
  background: #2D7CB4;
  border:none;
  margin-top: 20px;
  border-radius: .25rem;
  font-weight: 600;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transition: 100ms ease;
  cursor: pointer;
  &:hover{
    background: #276B9E;
    color: #fff;
  }
`
const TitleForm = styled.h1`
  color: #2D2D37;
  width: 400px;
  font-size: 40px;
  font-weight: 400;
  padding-bottom: 10px;
  ${mobile({fontSize:30,width:'auto'})}
`
const Box=styled.div`
  display:flex;
  align-items:center;
  justify-content:center;
  padding:20px;
  width:100%;
  margin-top:20px;
  border-radius:5px;
  font-weight: 600;
`

const Mapouter=styled.div`
   text-align:center;
   ${mobile({width:'auto',height:'50vh'})};
`
const Gmapcanvas=styled.div`
    position: relative;
    overflow:hidden;
    background:none!important;
   ${mobile({width:'auto',height:'50vh'})};
`
const Iframe=styled.iframe`
  width: 100%;
  height: 465px;
  border-radius: .5rem;
  border: 12px solid #fff;
  ${mobile({height:'50vh'})};
`


const Contact = () => {

  const formRef=useRef();
  const [name,setName]=useState('');
  const [tel,setTel]=useState('');
  const [email,setEmail]=useState('');
  const [message,setMessage]=useState('');


  const handleSubmit=(e)=>{
      e.preventDefault();
      if(name && email && message && tel){
           emailjs.sendForm('service_mpr5ozt', 'template_hxr8bjq', formRef.current, 'user_lbJ6yyht9ePek2vhAZKRJ').then((result) => {
              console.log(result.text);
              successMsg();
          }, (error) => {
              console.log(error.text);
          });
      }
  }

  const Reset=()=>{
    document.getElementById('name').value='';
    document.getElementById('email').value='';
    document.getElementById('message').value='';
    document.getElementById('tel').value='';
  }

  const successMsg=()=>{
      document.getElementById('confirmation').style.display='inline';
      document.getElementById('confirmation').innerHTML='Message Envoyé...';
      document.getElementById('confirmation').style.color='white';
      document.getElementById('confirmation').style.background='#6294AD';
      Reset();
      setTimeout(MsgNone,3000);
  }
  const MsgNone=()=>{
    document.getElementById('confirmation').style.display='none';
  }


  return <>
    <TopContainerTitle title='Contactez nous'/>
    <Container className='contact'>
      <Form 
        ref={formRef} onSubmit={handleSubmit}
        initial={{ opacity: 0 ,scale:1.1}}
        animate={{ opacity: 1 ,scale:1}}
        transition={{duration:.5}}
      >
          <TitleForm >Contactez-nous</TitleForm>
          <div style={{width:100,height:5,background:'#2D7CB4',marginBottom:30}}/>
          <Input type='text' placeholder='Nom' id='name' name='user_name' required onChange={(e)=>{setName(e.target.value)}}/>
          <Input type='email' placeholder='Email' id='email' name='user_email' required onChange={(e)=>{setEmail(e.target.value)}}/>
          <Input type='tel' pattern="^[+]?[0-9]{10,15}$" placeholder='Numéro de téléphone' id='tel' name='user_tel' required onChange={(e)=>{setTel(e.target.value)}}/>
          <DescInput rows='8' id='message' name='message' placeholder='Description...' required onChange={(e)=>{setMessage(e.target.value)}}></DescInput>
          <Button
            whileHover={{scale:1.01,transition:{duration:.1}}}
          >Envoyer</Button>
          <Box id='confirmation'></Box>
      </Form>
      <LocationContainer
        className='location-container'
        initial={{ opacity: 0 ,x:300}}
        animate={{ opacity: 1,x:0,transition:{duration:.2} }}
      >
        <Square/>
        <Content
          initial={{ opacity: 0 ,scale:0}}
          animate={{ opacity: 1,scale:1}}
          transition={{duration:.4}}
        >
          <Mapouter>
            <Gmapcanvas class="gmap_canvas">
                <Iframe title='bsiteam location' id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13277.066700900625!2d-7.372185357136414!3d33.70204780708439!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda7b7ba71d14649%3A0x7f8038daed20e7a6!2sBSITEAM!5e0!3m2!1sen!2sma!4v1645380841914!5m2!1sen!2sma" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" ></Iframe>
            </Gmapcanvas>
          </Mapouter>
        </Content>
      </LocationContainer>
    </Container>
    <Footer/>
  </>;
};

export default Contact;
