import dell from './assets/dell.svg'
import hp from './assets/hp.svg'
import hpe from './assets/helion.svg'
import lenovo from './assets/lenovo.svg'
import asus from './assets/asus.svg'
import acer from './assets/acer.svg'
import canon from './assets/canon.svg'
import triumph from './assets/triumph.svg'
import brother from './assets/brother.svg'
import epson from './assets/epson.svg'
import casio from './assets/casio.svg'
import cisco from './assets/cisco.svg'
import alcatel from './assets/alcatel-lucent.svg'
import grandstream from './assets/grandstream.svg'
import seagate from './assets/seagate.svg'
import lacie from './assets/lacie.svg'
import transcend from './assets/transcend.svg'
import hikvision from './assets/hikvision.svg'
import fellowes from './assets/fellowes.svg'
import eaton from './assets/eaton.svg'
import apc from './assets/apc.svg'
import dLink from './assets/dLink.svg'
import tpLink from './assets/tpLink.svg'
import jabra from './assets/jabra.svg'
import microsoft from './assets/microsoft.svg'
import kaspersky from './assets/kaspersky.svg'
import fortinet from './assets/fortinet.svg'
import tommatech from './assets/tommatech.svg'
import teletek from './assets/teletek.svg'
import samsung from './assets/samsung.svg'
import huawei from './assets/huawei.svg'
import apple from './assets/apple.svg'
import img1 from './assets/img1.jpg'
import img2 from './assets/img2.jpeg'
import img3 from './assets/img3.jpg'
import img4 from './assets/img4.jpeg'
import img5 from './assets/img5.jpg'


export const ListItemImages=[
    {key:1,url:dell,alt:'dell'},
    {key:2,url:hp,alt:'hp'},
    {key:3,url:hpe,alt:'helion'},
    {key:4,url:lenovo,alt:'lenovo'},
    {key:5,url:asus,alt:'asus'},
    {key:6,url:acer,alt:'acer'},
    {key:7,url:canon,alt:'canon'},
    {key:8,url:triumph,alt:'triumph'},
    {key:9,url:brother,alt:'brother'},
    {key:10,url:epson,alt:'epson'},
    {key:11,url:casio,alt:'casio'},
    {key:12,url:cisco,alt:'cisco'},
    {key:13,url:alcatel,alt:'alcatel-lucent'},
    {key:14,url:grandstream,alt:'grandstream'},
    {key:15,url:seagate,alt:'seagate'},
    {key:16,url:lacie,alt:'lacie'},
    {key:17,url:transcend,alt:'transcend'},
    {key:18,url:hikvision,alt:'hikvision'},
    {key:19,url:fellowes,alt:'fellowes'},
    {key:20,url:eaton,alt:'eaton'},
    {key:21,url:apc,alt:'apc'},
    {key:22,url:dLink,alt:'dLink'},
    {key:23,url:tpLink,alt:'tpLink'},
    {key:24,url:jabra,alt:'jabra'},
    {key:25,url:microsoft,alt:'microsoft'},
    {key:26,url:kaspersky,alt:'kaspersky'},
    {key:27,url:fortinet,alt:'fortinet'},
    {key:28,url:tommatech,alt:'tommatech'},
    {key:29,url:teletek,alt:'teletek'},
    {key:30,url:samsung,alt:'samsung'},
    {key:31,url:huawei,alt:'huawei'},
    {key:32,url:apple,alt:'apple'},
]

export const ListServicesData=[
    {
        id:1,
        title:"Intégration des solutions IT et fourniture de matériels informatique :",
        list:[
            "Intégrateur de solutions d'infrastructures sécurisées et de solutions informatiques",
            "Téléphonie",
            "Serveurs, Ordinateurs, Imprimante & Copieur, Réseaux & télécommunication",
        ],
        img:img1,
        ltr:false
    },
    {
        id:2,
        title:"Intégration des solutions de sécurité informatique, physique et performance SI :",
        list:[
            "Optimisation de la sécurité internet de votre SI",
            "Gestion des parcs informatiques",
            "Audit de sécurité interne et externe de votre SI ",
            "Installation et fourniture de matériels de systèmes de vidéosurveillance, alarme, contrôle d’accès et incendies",
        ],
        img:img2,
        ltr:true
    },
    {
        id:3,
        title:"Intégration des solutions des énergies renouvelables :",
        list:[
            "Conseil, assistance technique et expertise pour des projets clés en main",
            "Fourniture de matériels de système photovoltaïque, système d’irrigation de pompage solaire, chauffe-eau solaire, plaques solaires, batteries solaire …. ",
            "Audits énergétiques dans l’industrie et le tertiaire",
        ],
        img:img4,
        ltr:false
    },
    {
        id:4,
        title:"Fourniture de bureau consommable et objets personnalisés :",
        list:[
            "Toners original avec certificat d’authenticité, toners compatible et cartouches",
            "Papeterie : écriture, correction, archivage, et accessoires",
            "Machines de bureau, rangement, et accessoires",
            "Cadeaux d’entreprises et objets personnalisés ",
        ],
        img:img3,
        ltr:true
    },
  
    {
        id:5,
        title:"Conseils, formations & recrutement :",
        list:[
            "Accompagnement à la mise en place des systèmes de management adaptés ",
            "Formation professionnelle",
            "Outsourcing de recrutement",
        ],
        img:img5,
        ltr:false
    },
  
   
]

export const ListPiliers=[
    {id:1,firstchar:'T',title:'echnique d’experts',desc:'Intervenants experts mettant à votre disposition notre valeur ajoutée et savoir faire.'},
    {id:2,firstchar:'E',title:'Efficacité & Performance',desc:'Solutions fiables professionnelles et efficiences.'},
    {id:3,firstchar:'A',title:'daptation de la solution',desc:'Solutions sur mesure afin de mieux répondre à vos besoins.'},
    {id:4,firstchar:'M',title:'éthode Andragogique',desc:'Fluidification des outils et processus de travail Accompagnement terrain.'},
]