import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { mobile } from '../Responsive';
import Partenaires from './Partenaires';
import logoGray from '../assets/bsiGray.svg'
import linkedin from '../assets/linkedin.svg'
import location from '../assets/location.svg'
import phone from '../assets/phone.svg'
import email from '../assets/email.svg'
import hpbusinesspartner from '../assets/hpb.png'
import fb from '../assets/fb.svg'


const Container=styled.div`
    color: #fff;
    display: flex;
    flex-direction: column;
    ${mobile({width:'100vw',overflow: 'hidden'})}
`
const FooterContainer = styled.div`
  background: #363636;
  display: flex;
  padding: 30px 100px 10px 100px;
  ${mobile({flexDirection:'column',padding:'10px 20px'})}
`
const Left = styled.div`
  flex: 1;
  ${mobile({padding:0})}
`
const Title = styled.h1`
  color: #ddd;
  font-size: 18px;
  padding-bottom: 15px;
  ${mobile({padding:10})}
`
const Desc =styled.p`
  font-size: 11px;
  color: rgba(238, 238, 238,.85);
  text-align: justify;
  width: 400px;
  ${mobile({width:'100%',padding:'20px 10px 0 10px'})}
`
const SocialList=styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  ${mobile({marginTop:15,justifyContent:'start'})}
`
const Center = styled.div`
  flex: 1;
  ${mobile({display:'flex',flexDirection:'column',justifyContent:'center'})}
`
const List =styled.ul`
  list-style: none;
  padding-left: 15px;
  ${mobile({flexDirection:'column',paddingLeft:40})}
`
const Item =styled.li`
  color: #bbb;
  display: flex;
  padding-top: 5px ;
  font-weight: bold;
  &:hover{
    color: #fff;
  }
`
const Right = styled.div`
  flex: 1;
  ${mobile({paddingBottom:30})}
`
const DescI = styled.h3`
  color: #bbb;
  padding-left: 15px;
  font-weight: 400;
  font-size: 12px;  
`
const ListI =styled.ul`
  list-style: none;
  padding-left: 20px;
  ${mobile({display:'flex',flexDirection:'column',paddingLeft:20})}
`
const ItemI =styled.li`
  display: flex;
  align-items:center;
  color: #fff;
  padding-top: 20px ;
  font-weight: 400;
`
const BottomB=styled.div`
  color: #bbb;
  background: #2C2C2C;
  text-align:center;
  padding: 6px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 11px;
`
const ImgHpB=styled.img`
  padding-right: 30px;
  margin-left: 150px;
  ${mobile({paddingRight:20,marginLeft:120})}
`

const Footer = () => {

  const GoTop=()=>{
    var navbarList=document.querySelector('.navbaritems');
    navbarList.classList.remove('navbarlist');
    window.scrollTo(0, 0);
}

  const linkStyle={color:'#ccc',paddingLeft:10,fontWeight:400,fontSize:12,textDecoration:'underline',paddingBottom:10};

  return <Container>
    <Partenaires/>
    <FooterContainer >
      <Left>
        <Title><Link to='/'><img src={logoGray} height={50} alt='bsiteam'/></Link></Title>
        <Desc>BSITEAM est le fruit d’une volonté d’associer des compétences
          professionnelles et expertises techniques au sein d’une même société.
          Crée en 2019, offrant une gamme diversifiée de services et prestations,
          mettant le savoir faire de ses équipes à la disposition de ses partenaires</Desc>
          <div style={{display:'flex'}}>

          <SocialList>
            <a  href='https://www.linkedin.com/company/bsiteam/' target='_blank' rel="noopener noreferrer" style={{marginLeft:20}}>
              <img src={linkedin} alt='linkedin'/>
            </a>
            <a  href='https://web.facebook.com/BSI-Team-102619871190450' target='_blank' rel="noopener noreferrer" style={{marginLeft:10}}>
              <img src={fb} alt='linkedin'/>
            </a>
          </SocialList>
            <ImgHpB src={hpbusinesspartner} width={150} alt='hp besiness partner'/>
          </div>
      </Left>
      <Center >
        <Title>Navigation</Title>
        <List >
            <Item>• <Link to='/' style={linkStyle} onClick={GoTop}>Accueil</Link></Item>
            <Item>• <Link to='/about' style={linkStyle} onClick={GoTop}>À propos de nous</Link></Item>
            <Item>• <Link to='/services' style={linkStyle} onClick={GoTop}>Services</Link></Item>
            <Item>• <Link to='/contact' style={linkStyle} onClick={GoTop}>Contactez nous</Link></Item>
        </List>
      </Center>
      <Right>
        <Title>Info</Title>
        <ListI>
            <ItemI><img src={location} alt='location'/><DescI>Les orchidées 3, Imm D Boulevard Mohammed VI, 28800</DescI></ItemI>
            <ItemI><DescI>•&nbsp;&nbsp; Centrale Park Immeuble D, 2ème étage N°15 - Mohammedia</DescI></ItemI>
            <ItemI><img src={phone} alt='phone numbers'/><DescI>+212 520 688 428 &nbsp;•&nbsp; +212 660-966925</DescI></ItemI>
            <ItemI><img src={email} alt='email'/><DescI>Contact@bsiteam.ma</DescI></ItemI>
        </ListI>
      </Right>
    </FooterContainer>
    <BottomB>© 2022 Business Services Informatique</BottomB>
  </Container>;
};

export default Footer;
