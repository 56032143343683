import React from 'react'
import styled from 'styled-components'
import { mobile } from '../../Responsive'

const Container=styled.div`
    height:380px;
    background: ${props=>props.bg};
    border-radius: 40px;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

`
const Image=styled.img`
width: 37%;
order:${props=>props.ltr && 2};
`
// const Infos=styled.div`
//     padding-left: 20px;
// `
// const Title=styled.div``
// const Desc=styled.div``
// const Price=styled.div``
const Infos=styled.div`
order:${props=>props.ltr && 1};
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 20px;
  gap: 30px;
  ${mobile({width:'100%'})}
`
const Title=styled.h1`
  font-size: 22px;
  color: ${props=>props.color};
  ${mobile({fontSize:20})}
`
const Desc=styled.p`
font-size: 12px;
color: ${props=>props.color};
`
const DescPrice=styled.p`
font-size: 12px;
color: ${props=>props.color};
`
const Price=styled.span`
  color:${props=>props.color};
  font-size: 20px;
  font-weight: 900;
`

const Section = (props) => {
  return (
    <Container bg={props.bg} >
        <Image src={props.img} width={200} ltr={props.ltr}/>
          <Infos ltr={props.ltr}>
            <Title color={props.colorTitle}>{props.title}</Title>
            <Desc color={props.colorDesc}>{props.desc}</Desc>
            <DescPrice color={props.colorDescPrice}>à seulement :</DescPrice>
            <Price color={props.colorPrice}>{props.price} <span style={{fontSize:'14px'}}>DH/HT</span></Price>
          </Infos>
    </Container>
  )
}

export default Section