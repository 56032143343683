import React from 'react'
import styled from 'styled-components'
import Section from './components/Section'
import { mobile } from '../Responsive'
import asuspromo from './assets/asus.png'
import pc1 from './assets/asus1.png'
import pc2 from './assets/asus2.png'
import pc3 from './assets/asus3.png'
import Footer from './components/Footer'

const Container=styled.div`
    position: relative;
    z-index: 11111;
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #7160AC;
`
const ContainerP=styled.div`
    width: 40%;
    ${mobile({width:'100%'})}
`


const Promotion7 = () => {
  return (
    <Container>
        <ContainerP>
            <img src={asuspromo} alt='asus promotion' style={{width:'100%'}}/>
            <Section 
                img={pc1} 
                title='ASUS E203N'
                desc='ASUS E203N Intel Celeron N3350 11,6 4Go 128Go SSD Win 10 GREY 12M'
                price='2400'
                ltr={true}
                bg='#9A90CC'
                colorTitle='#fff'
                colorPrice='#fff'
                colorDescPrice='#5B44AC'
                colorDesc='#fff'
            />
            <Section 
                img={pc2} 
                title='ASUS X509'
                desc='ASUS X509 i3-10110U 15.6 HD 4GO 1TO Win 10 SLATE GREY 12M'
                price='3700'
                ltr={false}
                bg='#9A90CC'
                colorTitle='#fff'
                colorPrice='#fff'
                colorDescPrice='#5B44AC'
                colorDesc='#fff'
            />
            <Section 
                img={pc3} 
                title='ASUS R565E'
                desc='ASUS R565E Intel i5-1135G7 15.6 HD 8G 512Go SSD Win10 MX330 2G Silver 12M'
                price='6150'
                ltr={true}
                bg='#9A90CC'
                colorTitle='#fff'
                colorPrice='#fff'
                colorDescPrice='#5B44AC'
                colorDesc='#fff'
            />
            <br/>
            <br/>
            <Footer background='#9A90CC' borderRaduis={true}/>
        </ContainerP>
    </Container>
  )
}

export default Promotion7