import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {mobile} from '../Responsive';
import bg from '../assets/background.jpg'

const Conatiner=styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content:center;
  padding:0 20px;
  background: ${props=>props.ltr && 'linear-gradient(to right,rgba(45, 124, 180, .7),rgba(45, 124, 180, .3)) ,url('+bg+')'};
  background-size: cover;
  ${mobile({flexDirection:'column',padding:0,margin:'80px 0',overflow:'hidden'})};
`
const Left=styled.div`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px 0;
  color: ${props=>props.ltr && '#fff' };
  order: ${props=>props.ltr && 1 };
  ${mobile({padding:20,flex:'none',order:0})};
`
const Right=styled.div`
flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props=>props.ltr && '#fff' };
  order: ${props=>props.ltr && 0} ;
  padding: 80px 0;
  ${mobile({flex:'none',padding:20,order:1})};
`
const RightContainer =styled.div`
  width: 100%;
  border: 1px solid ${props=>props.ltr ? '#fff' : '#2D7CB4' };
  ${props=>props.ltr ? 'border-right:none': 'border-left:none'};
  padding:15px 50px;
  ${mobile({borderLeft:'none',borderRight:'none',borderWidth:2})}
`
const TitleService=styled.h4`
  color: ${props=>props.ltr ? '#fff' : '#2D7CB4' };
  font-family: 'Montserrat';
  font-size: 27px;
  font-weight: 400;
  padding-bottom: 25px;
  z-index: 100;
  ${mobile({fontSize:19,width:'auto'})};
`
const List=styled.ul`
  flex: 3;
  list-style: square;
  padding-left: 50px;
  ${mobile({padding:0})};
`
const Item=styled.li`
  font-family: 'Montserrat';
  padding:8px 0;
  font-size: 13.5px;
  font-weight: 500;
  color: ${props=>props.ltr ? '#fff' : '#2E5A77'};
  ${mobile({fontSize:13,padding:'10px 0',width:'auto'})};
`
const Image=styled.img`
  z-index: 11;
  height: auto;
  width: 100%;
  transition: 200ms ease;
  ${mobile({padding:0,height:'auto',width:'100vw'})};
`

const Service = (props) => {
  return <Conatiner
  className='service'
  initial={{ opacity: 0, scale:.8,x:-100,y:100}}
  animate={{ opacity: 1,scale:1 ,x:0,y:0}}
  transition={{duration:.8}}
  ltr={props.ltr}
  >
          <Left className='left' ltr={props.ltr} >
            <Image className='imgService'  src={props.img} alt={props.title}/>
          </Left>
          <Right className='right' ltr={props.ltr}>
            <RightContainer className='containerinfos' ltr={props.ltr}>
              <TitleService className='titles' ltr={props.ltr}>{props.title}</TitleService>
              <List>
                  {props.list.map((item)=>(
                    <Item className='items' ltr={props.ltr}>{item}</Item>
                  ))}
              </List>
            </RightContainer>
          </Right>
  </Conatiner>;
};

export default Service;
