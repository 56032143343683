import React from 'react';
import styled from 'styled-components';
import { mobile } from '../Responsive';
import shema from '../assets/shema.svg'
import piliers from '../assets/piliers.svg'
import { ListPiliers } from '../data';

const Container=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    margin-bottom: 170px;
    ${mobile({marginTop:-50})}
`
const TitleContainer=styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    margin-bottom: 70px;
    ${mobile({width:320})}
`
const TitleT=styled.h1`
    color: #333;
    font-family:'Montserrat' ;
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 500;
    padding-bottom:10px;
  ${mobile({fontSize:26})}
`
const Shemaphone=styled.img`
    display: none;
    ${mobile({display:'inline',width:325,marginLeft:-20})}
`
const Shema=styled.img`
    height: 190px;
    ${mobile({display:'none'})}
`
const Grid=styled.div`
    padding:0 50px;
    display: flex;
    justify-content: center;
    gap: 120px;
    flex-wrap: wrap;
    ${mobile({gap:70,width:200,display:'none'})}
`
const Item=styled.div`
    margin-top: -5px;
    width: 210px;
    height: 170px;
    background: #fff;
    border-radius: 10px;
    border:1px solid #2D7CB4;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    ${mobile({width:150,height:130,marginLeft:-90,})}
`
const Title=styled.h1`
    font-size: 16px;
    color: #2E5A77;
    margin-bottom: 15px;
    text-align: center;
    text-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
    ${mobile({fontSize:14})}
`

const Desc=styled.p`
    text-align:center;
    padding: 5px;
    font-weight:400;
    ${mobile({fontSize:11})}
`


const Piliers = () => {

    const spanStyle={color:'#fff',background:'#2D7CB4',fontSize:20,padding:'2px 6px',borderRadius:5,marginRight:1,boxShadow:'0px 4px 4px rgba(0, 0, 0, 0.25)'};

  return <Container className='piliers'>
      <TitleContainer>
        <TitleT>Piliers BSI</TitleT>
        <div style={{height:2,width:250,background:'#2D7CB4',marginLeft:0}}/>
      </TitleContainer>
      <Shema src={shema} alt='shema-piliers'/>
      <Shemaphone src={piliers} alt='shema-piliers' />
      <Grid className='grid'>
          {ListPiliers.map((item)=>(
            <Item className='item' key={item.id}>
              <Title className='title'><span style={spanStyle}>{item.firstchar}</span>{item.title}</Title>
              <Desc className='desc'>{item.desc}</Desc>
          </Item>
          ))}
      </Grid>
  </Container>;
};

export default Piliers;
