import React from 'react'
import styled from 'styled-components'
import { mobile } from '../Responsive'
import img from './assets/lenovo.png'
import Footer from './components/Footer'

const Container=styled.div`
    position: relative;
    z-index: 11111;
    background: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`
const ContainerP=styled.div`
    width: 60%;
    ${mobile({width:'100%'})}
`

const Promotion4 = () => {
  return (
    <Container>
        <ContainerP>
            <img src={img} style={{width:'100%'}} alt='lenovo promotion'/>
            <div style={{padding:'20px 50px',background:'#ccc'}}>
                <ul>
                    <li style={{marginBottom:10}}>Valable jusqu'au 11 Mars 2022.</li>
                    <li>Dans la limite du stock disponible.</li>
                </ul>
            </div>
            <Footer background='#eee'/>
        </ContainerP>
    </Container>
  )
}

export default Promotion4