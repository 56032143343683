import React from 'react'
import styled from 'styled-components'
import { mobile } from '../Responsive'
import Section from './components/Section'
import pr from './assets/proomo.jpg'
import pc from './assets/labpcdell.png'
import Footer from './components/Footer'

const Container=styled.div`
    position: relative;
    z-index: 11111;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #584B9D;
`
const ContainerP=styled.div`
    width: 40%;
    ${mobile({width:'100%'})}
`

const Promotion10 = () => {
  return (
    <Container>
        <ContainerP>
            <img src={pr} alt='promotion' style={{width:'100%'}}/>
            <Section 
                img={pc} 
                title='Dell Vostro 3500'
                desc='Dell Vostro 3500  i3-1115G4 15.6" HD 4GB 256GB SSD Ubuntu 12M'
                price='4 399'
                ltr={true}
                bg='#9183D2'
                colorTitle='#fff'
                colorPrice='#fff'
                colorDescPrice='#330066'
                colorDesc='#fff'
            />   
            <br/>
            <br/>
            <Footer background='#9183D2' borderRaduis={true} />
        </ContainerP>
    </Container>
  )
}

export default Promotion10