import React from 'react'
import styled from 'styled-components'
import header from './assets/header.png'
import imgpromo from './assets/imgpromo.png'
import pc1 from './assets/pc1.png'
import pc2 from './assets/pc2.png'
import pc3 from './assets/pc3.png'
import bsi from '../assets/bsiGray.svg'
import ln from '../assets/linkedin.svg'
import fb from '../assets/fb.svg'
import link from './assets/link.png'
import { mobile } from '../Responsive'

const Container=styled.div`
    position: relative;
    z-index: 111111;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #3195F3;
`
const ImageHeader=styled.img`
  width: 40%;
  ${mobile({width:'90%'})}
`
const Image=styled.img`
width:40%;
${mobile({width:'90%'})}
`
const Item=styled.div`
  background: #fff;
  width: 40%;
  border-radius: 35px;
  height: 350px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 20px;
  ${mobile({width:'90%',flexDirection:'column',padding:20,height:'auto'})}
`
const ImageItem=styled.img`
  width: 45%;
  ${mobile({width:'80%'})}
`
const Infos=styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 100px 20px;
  gap: 30px;
  ${mobile({width:'100%'})}
`
const Title=styled.h1`
  font-size: 22px;
  color: #0071C5;
  ${mobile({fontSize:20})}
`
const Desc=styled.p`
font-size: 14px;
`
const Price=styled.span`
  color: red;
  font-size: 20px;
  font-weight: 900;
`
const Section=styled.div`
  background: #545454;
  color: #fff;
  width: 40%;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  ${mobile({width:'100%'})}
`
const Footer=styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #0D151C;
  width: 40%;
  padding: 20px 0;
  ${mobile({width:'100%'})}
`
const Social=styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 200px;
  margin-bottom: 50px;
`
const ImgS=styled.img`
width: 30px;
`
const Bottom=styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  gap: 10px;
`
const Border=styled.div`
  width:40%;
  height:5px;
  background:#FEB04B;
  ${mobile({width:'100%',marginTop:30})}
`

const Promotion = () => {
  return (
    <Container>
        <a href='https://bsiteam.ma/' style={{width:'100%',textAlign:'center'}}>
          <ImageHeader src={header} />
        </a>
        <Image src={imgpromo}/>
        <Item>
          <ImageItem src={pc1}/>
          <Infos>
            <Title>HP Notebook Celeron</Title>
            <Desc>HP 15 Celeron N4020 dual 15.6" 4GB 1TB W10H6 Gris</Desc>
            <Desc>à partir de :</Desc>
            <Price>2 849 <span style={{fontSize:'14px'}}>DH/HT</span></Price>
          </Infos>
        </Item>
        <Item>
          <ImageItem src={pc2}/>
          <Infos>
            <Title>HP Notebook i3</Title>
            <Desc>HP 15 i3-1115G4 15,6" 4GB 256 GB SSD Win 10H SL Gris</Desc>
            <Desc>à partir de :</Desc>
            <Price>4 235 <span style={{fontSize:'14px'}}>DH/HT</span></Price>
          </Infos>
        </Item>
        <Item>
          <ImageItem src={pc3}/>
          <Infos>
            <Title>HP Notebook i5</Title>
            <Desc>HP 15 i5-1135G7 15,6" 8GB 256 GB SSD Win 10H SL Gris</Desc>
            <Desc>à partir de :</Desc>
            <Price>5 665 <span style={{fontSize:'14px'}}>DH/HT</span></Price>
          </Infos>
        </Item>
          <Border />
        <Section>
          <h4 style={{marginBottom:10,fontSize:13}}>CONDITIONS GÉNÉRALES</h4>
          <span >* Promo valable uniquement sur le web pour une facturation entre le 18 et le 28 Février 2022.<br/><br/>
          * Dans la limite du stock disponible </span>
        </Section>
      <Footer>
        <a href='https://bsiteam.ma/' style={{width:'100%',textAlign:'center'}}>
        <img src={bsi} style={{width:100,marginBottom:30}}/>
        </a>
        <Social>
          <a href='https://www.linkedin.com/company/bsiteam/' target='_blank'><ImgS src={ln} alt='linkedin' /></a>
          <a href='https://web.facebook.com/BSI-Team-102619871190450' target='_blank'><ImgS src={fb} alt='facebook'/></a>
          <a href='https://bsiteam.ma/' target='_blank'><ImgS src={link} alt='link'/></a>
        </Social>
        <Bottom>
          <span style={{fontSize:12,textAlign:'center',marginBottom:40}}>Les orchidées3, Imm D Boulevard Mohammed VI, 28800<br/>
          Tél: +212 660-966925 / +212 660-505245 / +212 661 724 204</span>
        <div style={{background:'#eee',height:1,width:550}}/>
          <span style={{fontSize:10,fontWeight:100,textAlign:'center'}}>Vous recevez cet email car vous êtes abonné à la newsletter BSITEAM.<br/>
         Pour mettre à jour vos centres d'intérêt BSITEAM, <a style={{color:'#fff',textDecoration:'underline'}} href='https://bsiteam.ma/'>suivez ce lien</a>
         </span>
        </Bottom>
      </Footer>
    </Container>
  )
}

export default Promotion