import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { mobile } from '../Responsive';
import Footer from '../components/Footer';
import Quality from '../components/Quality';
import TopContainerTitle from '../components/TopContainerTitle';
import Piliers from '../components/Piliers';
import imgAbout from '../assets/about.svg'

const ContainerAll=styled.div`
  ${mobile({width:'100vw'})};
  overflow: hidden;
`
const Container=styled.div`
  height: 70vh;
  padding:150px 0 0 100px;
  display: flex;
  background: #fff;
  ${mobile({width:'100vw',flexDirection:'column',padding:0,marginBottom:0})};
`
const Left=styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`
const Title=styled(motion.h1)`
  color: #2D7CB4;
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 400;
  ${mobile({width:'100vw',padding:'100px 0 20px 0',textAlign:'center',fontSize:22,marginTop:0})};
`
const Desc=styled(motion.p)`
  text-align: justify;
  color: #2D2D37;
  font-size: 19px;
  font-weight: 400;
  width: 700px;
  padding:10px 50px 10px 25px;
  line-height: 1.8;
  ${mobile({width:'100vw',padding:'0 20px',fontSize:16,lineHeight:1.5})};
`
const Right=styled.div`
  flex: 1;
  padding: 0 100px;
  ${mobile({margin:'30px 20px',padding:0,display:'flex',justifyContent:'center'})};
`
const Image=styled(motion.img)`
  height: 350px;
  ${mobile({width:300,height:'auto',padding:0,marginBottom:0})};
`

const About = () => {
  return <ContainerAll>
    <TopContainerTitle title='à propos de nous'/>
    <Container className='about'>
      <Left>
        <Title className='title'
          initial={{ opacity: 0 ,x:-100}}
          animate={{ opacity: 1,x:0 }}
          transition={{duration:.3}}
        >Qui sommes-nous ?</Title>
        <Desc
        className='desc'
          initial={{ opacity: 0, scale:1.1}}
          animate={{ opacity: 1,scale:1 }}
          transition={{duration:.3}}
        >
          BSITEAM est une société basée sur la région Grand Casablanca, spécialisée dans l’intégration des solutions IT et Energie renouvelable, offrant une large gamme diversifiée de produits, services et prestations, ainsi que la possibilité d’adapter et personnaliser les besoins en présentant des solutions de pointes et sur mesure, mobilisant son capital humain composé d’ingénieurs et de techniciens expérimentés à la disposition de ses partenaires, en s’appuyant sur l’expertise, une technologie fiable, un service professionnel et des solutions solides.
        </Desc>
      </Left>
      <Right>
        <Image 
          src={imgAbout}
          alt='about-avatar'
          initial={{ opacity: 0, scale:1.1}}
          animate={{ opacity: 1,scale:1 }}
          transition={{duration:.3}}
        />
      </Right>
    </Container>
    <Quality/>
    <Piliers/>
    <Footer/>
  </ContainerAll>;
};

export default About;
