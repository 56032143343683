import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import {mobile} from '../Responsive'
import background from '../assets/background.jpg'

const Container=styled.div`
    background: linear-gradient(rgba(0,0,0,.75),rgba(0,0,0,.75)),url(${background});
    background-size:cover;
    height: 50vh;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    ${mobile({height:'30vh'})}
`
const Title=styled(motion.h1)`
  text-transform: uppercase;
  font-weight: 400;
  ${mobile({textAlign:'center',fontSize:28})}
`

const TopContainerTitle = (props) => {
  return <Container>
      <Title
        initial={{ opacity: 0 ,x:100}}
        animate={{ opacity: 1,x:0 }}
        transition={{duration:.3}}
      >{props.title} 
      </Title>
  </Container>;
};

export default TopContainerTitle;
