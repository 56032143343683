import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { mobile } from '../Responsive';
import { motion } from 'framer-motion';
import logo from '../assets/bsi.svg';
import logo2 from '../assets/bsi2.svg';
import menu from '../assets/menu.svg';
import closebar from '../assets/closebar.svg';


const Container=styled(motion.div)`
    position: fixed;
    z-index: 1000;
    width: 100%;
    height: 70px;
    transition:top 300ms ease;
    ${mobile({height:50})}; 
`
const Blur=styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 50px 0 60px;
    transition: 100ms ease-in-out;
    ${mobile({padding:'10px 30px',width:'100vw'})}; 
`
const LogoContainer=styled.div`
    user-select: none;
    -webkit-user-select: none;       
    -moz-user-select: none; 
    -ms-user-select: none; 
`
const Image=styled.img`
    width: 80px;
    margin-top: 120px;
    transition: 400ms ease-out;
    ${mobile({width:40,marginTop:40})}
`
const MenuContainer=styled.div`
    display: flex;
`
const List=styled.ul`
    display: flex;
    list-style: none;
    ${mobile({display:'none'})};

`
const Item=styled.li`
    text-transform: uppercase;
    padding: 10px 30px;
    cursor: pointer;
    &:hover{
        color:#E56766;
    }
    ${mobile({textAlign:'center'})}
`
const Toggle=styled.div`
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 10px 0 0;
    z-index: 1000;
    ${mobile({display:'flex'})};
`

const Navbar = () => {

    const [toggleClose,setToggelClose]=useState(false)

    useEffect(() => {
    //   window.addEventListener('scroll',()=>{
    //       var navbar=document.querySelector('.navbar');
    //       navbar.classList.toggle('sticky',window.scrollY > 50)
    //   })
      //Update
      var prevScrollpos=window.pageYOffset;
      window.addEventListener('scroll',()=>{
        var navbar=document.querySelector('.navbar');
      var currentScrollPos = window.pageYOffset;
        if(prevScrollpos > currentScrollPos){
            navbar.style.top='0'
            navbar.style.opacity='1'
            navbar.classList.toggle('sticky',window.scrollY > 1)
        }else{
          navbar.style.top='-70px'
          navbar.style.opacity='0'
      }
      prevScrollpos = currentScrollPos;
    })
      //---------
    }, []);

    const handleClick=()=>{
        var navbarList=document.querySelector('.navbaritems');
        navbarList.classList.remove('navbarlist');
        window.scrollTo(0, 0);
        handelToggle();
    }

    const NavbarStatus=()=>{
        var navbarList=document.querySelector('.navbaritems');
        navbarList.classList.toggle('navbarlist');
        handelToggle();
    }
    
    const handelToggle=()=>{
        setToggelClose(!toggleClose)
    }

  return <Container 
  className='navbar'
  initial={{ opacity: 0 ,scale:1.1}}
  animate={{ opacity: 1 ,scale:1}}
  transition={{duration:.5}}
  >
      <Blur className='blur'>
      <LogoContainer>
        <Link style={{display:'flex', alignItems:'center',textDecoration:'none'}} to='/' >
          <Image className='logo lgbsi logo-1' src={logo} alt='logo-bsiteam' />
          <Image className='logo lgbsi logo-2' src={logo2} alt='logo-bsiteam' />
        </Link>
      </LogoContainer>
      <MenuContainer className='navbaritems'>
            <List>
                <Item><NavLink className='navlink' exact activeClassName='active-link' to='/' onClick={handleClick}>Accueil</NavLink></Item>
                <Item><NavLink className='navlink' activeClassName='active-link' to='/about' onClick={handleClick}>À PROPOS DE NOUS</NavLink></Item>
                <Item><NavLink className='navlink' activeClassName='active-link' to='/services' onClick={handleClick}>Services</NavLink></Item>
                <Item><NavLink className='navlink' activeClassName='active-link' to='/contact' onClick={handleClick}>Contactez nous</NavLink></Item>
            </List>
      </MenuContainer>  
      <Toggle >
          {toggleClose ? <img src={closebar} alt='close' onClick={NavbarStatus}/> : <img src={menu} alt='menu' onClick={NavbarStatus}/>  }
      </Toggle>
      </Blur>

  </Container>;
};

export default Navbar;
